import staticContent from '@/components/static-content/index.vue';
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: 'photos-content',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      currentSlide: 0,
      initialLoad: true,
      start_slider: false,
    }
  },
  components: {
    Carousel,
    Slide,
    staticContent,
  },
  created() {
  },
  mounted() {

  },
  methods: {
    changeSlide(type) {
      let carousel = this.$refs.photosContentSlider;
      if (type === 'prev') {
        carousel.goToPage(carousel.getPreviousPage());
      } else {
        carousel.goToPage(carousel.getNextPage());
      }
    }
  }
}
