import photosContent from './components/photos-content/index.vue'
import emptyData from "@/components/empty-data/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {Carousel, Slide} from "vue-carousel";

export default {
  name:'photos',
  data(){
    return{
      initialLoad: true,
    }
  },
  created() {
    if (!this.beforeAfterData) {
      this.fetchBeforeAfterData();
    }
  },
  mounted() {},
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  components:{
    Carousel,
    Slide,
    emptyData,
    photosContent
  },
  computed: {
    ...mapGetters({
      beforeAfterData: 'beforeAfter/beforeAfterData',
      isLoadingBeforeAfter: 'beforeAfter/isLoadingBeforeAfter',
    })
  },
  methods:{
    ...mapActions({
      fetchBeforeAfterData: `beforeAfter/BEFORE_AFTER_DATA_GET`,
    }),
    ...mapMutations({
    }),
    changeSlide(type) {
      let carousel = this.$refs.photosSlider;
      if (type === 'prev') {
        carousel.goToPage(carousel.getPreviousPage());
       } else {
        carousel.goToPage(carousel.getNextPage());
      }
    }
  }
}
